import React from 'react';
import {
  FormFeedback, FormGroup, Label
} from 'reactstrap';
import Select from 'react-select';

const FormikSelectInput = ({
  field,
  form: { touched, errors, setFieldValue },
  disabled = false,
  onChange = () => {},
  ...props
}) => {
  const error = errors[field.name];
  const touch = touched[field.name];
  const { onChange: fieldChangeHandler, ...otherFieldProps } = field;
  const { inputprops, label, multiple } = props;
  const onChangeHandler = (e) => {
    onChange(e.value);
    if (!multiple) {
      setFieldValue(field.name, e.value);
    } else {
      /* eslint-disable max-len */
      setFieldValue(field.name, e.map((el) => el.value));
    }
  };
  const getValue = () => {
    if (inputprops.options && field.value) {
      return multiple
        ? field.value.map((el) => ({ value: el, label: el }))
        : ({ value: field.value, label: field.value });
    }
    return multiple ? [] : '';
  };

  return (
    <FormGroup>
      <Label for={inputprops.id} className="label-color">
        {label}
      </Label>
      <Select
        id={inputprops.id}
        {...otherFieldProps}
        {...props}
        onChange={onChangeHandler}
        type="select"
        placeholder="Sélectionner"
        value={getValue()}
        isMulti={multiple || false}
        isSearchable
        disabled={disabled}
        invalid={Boolean(touched[field.name] && errors[field.name])}
        options={inputprops.options.map((el) => ({ value: el, label: el }))}
      />

      {touch && error && <FormFeedback>{error}</FormFeedback>}
    </FormGroup>
  );
};

export default FormikSelectInput;
