import React, { useCallback, useState } from 'react';
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption
} from 'reactstrap';
import { Link, graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import classNames from 'classnames';

const HomeCarousel = () => {
  const {
    latestCarousels: { edges: latestCarousels }
  } = useStaticQuery(graphql`
    query {
      latestCarousels: allWordpressWpCarousel(sort: { fields: date, order: DESC }) {
        edges {
          node {
            acf {
              image {
                localFile {
                  childImageSharp {
                    # Specify the image processing specifications right in the query.
                    # Makes it trivial to update as your page's design changes.
                    fluid(maxWidth: 1110, maxHeight: 550, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              title
              link
            }
            date(locale: "fr", formatString: "d MMM YY")
          }
        }
      }
    }
  `);
  const items = latestCarousels.map(({ node: item }) => ({
    id: item.id,
    src: item.acf.image.localFile.childImageSharp.fluid,
    title: item.acf.title || '',
    url: item.acf.link && item.acf.link.split('.tn').pop(),
    altText: item.acf.title || ''
  }));
  const [animating, setAnimating] = useState(false);
  const [activeIndex, setIndex] = useState(0);

  const onExiting = () => {
    setAnimating(true);
  };

  const onExited = () => {
    setAnimating(false);
  };

  const next = useCallback(() => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setIndex(nextIndex);
  }, [animating, activeIndex, items]);

  const previous = useCallback(() => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setIndex(nextIndex);
  }, [animating, activeIndex, items]);

  const goToIndex = useCallback(
    (newIndex) => {
      if (animating) return;
      setIndex(newIndex);
    },
    [animating]
  );

  return (
    <Carousel activeIndex={activeIndex} next={next} previous={previous}>
      <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex} />
      {items.map((item) => (
        <CarouselItem
          onExiting={onExiting}
          onExited={onExited}
          key={item.id}
          className={classNames({ 'item-has-title': item.title })}
        >
          <Link to={item.url ? item.url : '#'}>
            <Img fluid={item.src} alt={item.altText} className="w-100" />
            {item.title && (
              <CarouselCaption captionText={item.caption} captionHeader={item.title} />
            )}
          </Link>
        </CarouselItem>
      ))}
      <CarouselControl direction="prev" directionText="Précédent" onClickHandler={previous} />
      <CarouselControl direction="next" directionText="Suivant" onClickHandler={next} />
    </Carousel>
  );
};

export default HomeCarousel;
