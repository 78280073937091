import React, { useState } from 'react';
import {
  Collapse, Button, CardBody, Card
} from 'reactstrap';
import Parser from 'html-react-parser';

const ToggleCard = ({ title, children }) => {
  const [toggle, setToggle] = useState(false);
  return (
    <div className="ToggleCard">
      <Button
        color="link"
        className={toggle ? 'ToggleCard__button active' : 'ToggleCard__button'}
        onClick={() => {
          setToggle(!toggle);
        }}
      >
        {Parser(title)}
      </Button>
      <Collapse isOpen={toggle}>
        <Card className="ToggleCard__content">
          <CardBody className="ToggleCard__body">{children}</CardBody>
        </Card>
      </Collapse>
    </div>
  );
};
export default ToggleCard;
