import React, { useState } from 'react';
import {
  Container,
  Row,
  Col,
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  NavItem,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  Input,
  Button,
} from 'reactstrap';
import { Link } from 'gatsby';

import logoLeft from '../assets/images/header_logos_left.png';

/*eslint-disable */
const Header = () => {
  const [isOpen, setOpen] = useState(false);
  const [dropdownOpen, setDropDownOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [partnerDropdownOpen, setPartnerDropDownOpen] = useState(false);
  return (
    <Container className="my-5">
      <Row>
        <Col lg="12">
          <Navbar light expand="lg" className="mx-auto">
            <NavbarBrand to="/" tag={Link} className="mr-auto">
              <img src={logoLeft} width="240" alt="CET Logo" className="pr-2 pr-md-0" />
            </NavbarBrand>
            <NavbarToggler className="hidden-md-up" onClick={() => setOpen(!isOpen)} />
            <Collapse navbar isOpen={isOpen} className="mx-auto navbar-toggleable-md" expand="lg">
              <Nav className="ml-auto">
                <NavItem className="NavbarElement">
                  <Link to="/" activeClassName="Link__active" className="nav-link">
                    Actualités
                  </Link>
                </NavItem>
                <NavItem className="NavbarElement">
                  <Link to="/presentation/" activeClassName="Link__active" className="nav-link">
                    Présentation
                  </Link>
                </NavItem>
                <Dropdown
                  className="NavbarElement position-static"
                  isOpen={dropdownOpen}
                  direction="down"
                  toggle={() => setDropDownOpen(!dropdownOpen)}
                  nav
                >
                  <DropdownToggle nav tag="a" href="#">
                    Financements
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem className="d-inline" tag={Link} to="/programme/culture/">
                      Le sous-programme Culture
                    </DropdownItem>
                    <DropdownItem className="d-inline" tag={Link} to="/programme/media/">
                      Le sous-programme MEDIA
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
                <Dropdown
                  className="NavbarElement position-static"
                  isOpen={partnerDropdownOpen}
                  direction="down"
                  toggle={() => setPartnerDropDownOpen(!partnerDropdownOpen)}
                  nav
                >
                  <DropdownToggle nav tag="a" href="#">
                    Recherche de partenaires
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem className="d-block partners" tag={Link} to="/inscription/">
                      Je cherche un partenaire/un projet
                    </DropdownItem>
                    <DropdownItem className="d-block partners" tag={Link} to="/partenaires/">
                      Base de données des organisations
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
                <NavItem className="NavbarElement">
                  <a
                    className="nav-link"
                    href="#newsletter"
                    onClick={() => {
                      setModalOpen(!modalOpen);
                    }}
                  >
                    Newsletter
                  </a>
                </NavItem>
              </Nav>
            </Collapse>
          </Navbar>
        </Col>
      </Row>
      <Modal
        isOpen={modalOpen}
        toggle={() => {
          setModalOpen(!modalOpen);
        }}
      >
        <ModalHeader
          toggle={() => {
            setModalOpen(!modalOpen);
          }}
        >
          Inscription à la newsletter
        </ModalHeader>
        <ModalBody>
          <form action="https://creativeeurope.us19.list-manage.com/subscribe/post" method="POST">
            <input type="hidden" name="u" value="04296e3b724769819d38c0a0b" />
            <input type="hidden" name="id" value="4143c1a5fd" />
            <FormGroup>
              <Label for="MERGE1" className="label-color">
                Prénom:
              </Label>
              <Input type="text" name="MERGE1" id="MERGE1" />
            </FormGroup>
            <FormGroup>
              <Label for="MERGE2" className="label-color">
                Nom:
              </Label>
              <Input type="text" name="MERGE2" id="MERGE2" />
            </FormGroup>
            <FormGroup>
              <Label for="MERGE0" className="label-color">
                Adresse email:
              </Label>
              <Input type="email" name="MERGE0" id="MERGE0" />
            </FormGroup>
            <Button
              color="secondary"
              className="mx-auto d-block text-center"
              size="lg"
              type="submit"
            >
              S&apos;inscrire
            </Button>
          </form>
        </ModalBody>
      </Modal>
    </Container>
  );
};
export default Header;
