import React from 'react';
import {
  FormFeedback, FormGroup, Input, Label
} from 'reactstrap';

const FormikInput = ({ field: { ...fields }, form: { touched, errors }, ...props }) => {
  const { label, id } = props;
  return (
    <FormGroup>
      {label && (
        <Label for={id} className="label-color">
          {label}
        </Label>
      )}
      <Input
        {...props}
        {...fields}
        invalid={Boolean(touched[fields.name] && errors[fields.name])}
      />
      <FormFeedback>
        {touched[fields.name] && errors[fields.name] ? errors[fields.name] : ''}
      </FormFeedback>
    </FormGroup>
  );
};
export default FormikInput;
