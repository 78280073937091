import React from 'react';
import {
  Container, Row, Col, Media
} from 'reactstrap';
import { Link } from 'gatsby';

import logoRight from '../assets/images/footer_logos_right.png';
import logoLeft from '../assets/images/header_logos_left.png';
import fbIcon from '../assets/images/fb.png';
import igIcon from '../assets/images/instagram.png';
import lkIcon from '../assets/images/linkedin.png';
import ytIcon from '../assets/images/youtube.png';

const Footer = () => (
  <Container>
    <Row className="Footer__top py-2 my-2">
      <Col md="3" className="align-self-center">
        <Media src={logoLeft} width="180" />
      </Col>
      <Col md={{ size: 6, offset: 3 }}>
        <div className="d-flex align-items-center">
          <Media
            src={logoRight}
            className="FooterItem__logo pr-2 ml-auto float-right"
            width="160"
          />
          <div className="mt-5 mt-md-0 float-right">
            <Link to="/contact/" activeClassName="Link__active" className="nav-link">
              Contact
            </Link>
          </div>
        </div>
      </Col>
    </Row>
    <Row className="py-2 my-2">
      <Col md="6">
        <p className="FooterItem__copyright">
          &copy;
          {` ${new Date().getFullYear()} `}
          Tous droits réservés, creative europe tunisia
        </p>
      </Col>
      <Col md="6" className="d-flex justify-content-end">
        <a
          className="ml-4 d-flex align-items-center social-icon"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.facebook.com/CETUNISIA/"
          title="Facebook"
        >
          <Media src={fbIcon} />
        </a>
        <a
          className="ml-2 d-flex align-items-center social-icon"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.instagram.com/creativeeuropetn"
          title="Instagram"
        >
          <Media src={igIcon} />
        </a>
        <a
          className="ml-2 d-flex align-items-center social-icon"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.linkedin.com/company/creative-europe-desk-tunisia"
          title="Linkedin"
        >
          <Media src={lkIcon} />
        </a>
        <a
          className="ml-2 d-flex align-items-center social-icon"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.youtube.com/channel/UCK78FWAx9nTzByJ0VKPNCoQ"
          title="Youtube"
        >
          <Media src={ytIcon} />
        </a>
      </Col>
    </Row>
  </Container>
);
export default Footer;
