import React from 'react';
import { Link } from 'gatsby';
import { Button as BootstrapButton } from 'reactstrap';

const Button = ({
  text, target, color, ...props
}) => (
  <BootstrapButton to={target} tag={target === '#' ? 'a' : Link} color={color} size="lg" {...props}>
    <span>{text}</span>
  </BootstrapButton>
);

export default Button;
