import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';

const ReactstrapRadioInput = ({
  field,
  form: {
    setFieldValue, values
  },
  disabled = false,
  ...props
}) => {
  const { id, label } = props;
  /* eslint-disable max-len */

  return (
    <FormGroup check>
      <Label for={id} check>
        <Input
          {...props}
          type="checkbox"
          name={field.name}
          checked={values[field.name] === field.value}
          value={field.value}
          disabled={disabled}
          onChange={(e) => {
            if (e.target && e.target.checked) {
              setFieldValue(field.name, 'yes');
            } else {
              setFieldValue(field.name, null);
            }
          }}
        />
        {label}
      </Label>
    </FormGroup>
  );
};

export default ReactstrapRadioInput;
