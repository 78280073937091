import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import Header from './Header';
import Footer from './Footer';

const Wrapper = ({ children }) => (
  <>
    <GoogleReCaptchaProvider
      reCaptchaKey="6Lc_TV0aAAAAAM34_ZNQAHrYVlggOHCH1pl7TVE5"
      language="fr"
      useRecaptchaNet
      useEnterprise={false}
      scriptProps={{
        async: true,
        defer: true,
        appendTo: 'body'
      }}
    >
      <Header />
      <div className="Content">{children}</div>
      <Footer />
    </GoogleReCaptchaProvider>
  </>
);

export default Wrapper;
