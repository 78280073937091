import React, { useState } from 'react';
import {
  Row, Col, InputGroup, InputGroupText, InputGroupAddon, Input
} from 'reactstrap';
import { graphql, useStaticQuery } from 'gatsby';
import Parser from 'html-react-parser';

import search from '../assets/images/search.png';

import ToggleCard from './ToggleCard';
import Button from './Button';

const Faq = () => {
  const {
    allWordpressWpQuestions: { edges },
  } = useStaticQuery(graphql`
    {
      allWordpressWpQuestions(sort: { fields: [date] }) {
        edges {
          node {
            id
            wordpress_id
            date
            title
            content
          }
        }
      }
    }
  `);
  const [searchQuery, setSearchQuery] = useState('');
  const questions = edges.filter((item) => {
    if (!searchQuery) return true;
    const {
      node: { title, content },
    } = item;
    const filtered = title.toLowerCase() + content.toLowerCase();
    return filtered.indexOf(searchQuery.toLowerCase()) !== -1;
  });
  return (
    <Row>
      <Col md="8">
        {questions.length ? (
          questions.map(({ node }) => (
            <ToggleCard key={node.id} title={node.title}>
              {Parser(node.content)}
            </ToggleCard>
          ))
        ) : (
          <p className="w-100 text-center">
            Aucun résultat ne correspond à vos critères de recherche.
          </p>
        )}
      </Col>
      <Col md="4">
        <InputGroup className="InputGroup__search">
          <Input
            placeholder="Recherche"
            onChange={(ev) => setSearchQuery(ev.target.value)}
            value={searchQuery}
          />
          <InputGroupAddon addonType="append">
            <InputGroupText>
              <img src={search} alt="Search" width="14" />
            </InputGroupText>
          </InputGroupAddon>
        </InputGroup>
        <div className="w-100 mt-5">
          <div className="contactForm__header mb-5">
            <Button
              className="w-100"
              size="lg"
              to="/contact"
              text="Posez votre question"
              target="_blank"
            />
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default Faq;
